<template>
  <div class=" w-full bg-white h-full rounded-[8px]">
    <div class="flex flex-col justify-start items-center h-full w-full" >
      <div class="text font-bold text-center !text-[1.125rem] !text-[#3C4549] w-full border-b py-[1rem] flex justify-center items-center h-[4.188rem]">
        Basic Settings
      </div>
      <!-- the heading part -->
      <div class="flex w-[35rem] flex-col justify-center items-center h-full px-3" >
        <div class="pt-8 w-full flex items-center">
          <div class="border-r border-r-[#F2F3F8] pr-2">
            <div style="width: 7.5rem !important; height: 7.5rem !important" class="profile_image" :style="brandImageLink(getBrandAdd.avatar)">

              <div class="input_file" v-if="!getBrandLoaders.image_upload">
                <input @change="uploadImageForBrand($event)" type="file" class="profile_input"
                       id="profile_input" data-cy="p-image">
                <label for="profile_input">
                  <i v-tooltip.top-center="'Change Image'" class="far fa-pencil"></i>
                </label>
              </div>
              <div class="input_file bg_opacity" v-else>
                <Loader></Loader>
              </div>
            </div>
          </div>
          <div class="pl-2 flex-1">
            <FloatingLabelInput
              id="email"
              v-model="getBrandAdd.name"
              type="text"
              label="Workspace Name"
              customClass="h-[3.5rem] bg-[#F4F6FA]"
              :errorType="validations.name || validations.nameLength  ? 'danger' : ''"
            >
              <template v-slot:error_message>
                <InputFieldMessage v-if="validations.name" :message="messages.name"></InputFieldMessage>
                <InputFieldMessage v-else-if="validations.nameLength" :message="messages.nameLength"></InputFieldMessage>
              </template>
            </FloatingLabelInput>
            <URLInputFloatingV2
              class="pt-3"
              id="url"
              v-model="getBrandAdd.url"
              :validations="validations"
              type="text"
              label="Link"
              customClass="h-[3.5rem] bg-[#F4F6FA]"
              :errorType="validations.url || validations.urlValid || validations.urlLength  ? 'danger' : ''"
            >
              <template v-slot:error_message>
                <InputFieldMessage v-if="validations.url" :message="messages.url"></InputFieldMessage>
                <InputFieldMessage v-else-if="validations.urlLength" :message="messages.urlLength"></InputFieldMessage>
                <InputFieldMessage v-else-if="validations.urlValid" :message="messages.urlValid"></InputFieldMessage>
              </template>
            </URLInputFloatingV2>
          </div>
        </div>
        <div class="pt-10 pb-11">
          <Button
            id="login-button"
            type="button"
            class="text-white bg-[#2560D7] hover:bg-blue-700"
            buttonClass="btn-lg"
            @click.prevent="handleStoreBrand()"
          >
            <template v-slot:label>Save</template>
            <template v-if="getBrandLoaders.store" v-slot:loader>
              <Loader></Loader>
            </template>
          </Button>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import {
  brandMessages,
  brandValidations,
  validLength,
} from "@/common/attributes";
import { DARK_PURPLE_LOADER_COLOR } from "@/common/constants";
import {brandTypes} from '@/state/modules/mutation-types.js'

import validaitonsWithMessageMixin from "@/mixins/validations/validations-with-message.js";
import { trackBrandCreated } from "@/common/methods";
import {workSpaceUpdateMixin} from "@/mixins/workSpace/create/workspaceUpdateMixin.js"
export default {
  components: {
    FloatingLabelInput: () => import("@/ui/ui-kit/v2/FloatingLabelInput.vue"),
    URLInputFloatingV2: () => import("@/ui/ui-kit/v2/URLInputFloatingV2.vue"),
    Button: () => import("@/ui/ui-kit/v2/Button.vue"),
    InputFieldMessage: () => import("@/ui/ui-kit/v2/InputFieldMessage.vue"),
    Loader: () => import("@/ui/ui-kit/v2/Loader.vue"),
  },
  mixins: [workSpaceUpdateMixin,validaitonsWithMessageMixin],
  data() {
    return {
      validations: brandValidations,
      messages: brandMessages,
    };
  },
  computed: {
    ...mapGetters(["getBrandAdd", "getBrandLoaders", "getProfile", 'getWorkspace']),
  },
  created () {
    this.$store.commit('SET_BRAND_ADD_VALUE', this.getWorkspace)
  },
  methods: {
    ...mapActions(["uploadImageForBrand", "storeBrand"]),
    async handleStoreBrand () {

      if (this.getBrandLoaders.image_upload) {
        this.$store.dispatch('toastNotification', {message: this.getUploadImageMessage, type: 'error'})
        return
      }

      this.validations.name = this.requiredCheck(this.getBrandAdd.name)
      this.validations.url = this.requiredCheck(this.getBrandAdd.url)
      this.getBrandAdd.url = this.urlConcatenation(this.getBrandAdd.url)
      this.validations.urlValid = !this.isValidURL(this.getBrandAdd.url)
      if (!this.validations.url) this.validations.url_valid = !this.isValidURL(this.getBrandAdd.url)
      let result = Object.keys(this.validations).every(k => this.validations[k] === false)
      if (result) {
        this.getBrandLoaders.store = true
        await this.storeBrand()
      }
      this.getBrandLoaders.store = false
    }
  },
  watch: {
    'getBrandAdd.name' (value) {
      if (value && value.length > 0) {
        this.validations.name = false
        this.validations.nameLength = !this.maxLength(value, 100)
      }
    },
    'getWorkspace._id' () {
      this.$store.commit('SET_BRAND_ADD_VALUE', this.getWorkspace)
    }
  },
};
</script>

<style lang="less" scoped>
.basic_details_form > .basic_details_form_div {
  grid-template-rows: 4.71rem 64.78rem;
}

.heading_shadow {
  // box-shadow: 0px 3px 10px 0px #D2D5DF;
  border-bottom: 1px solid #d2d5df;
}
</style>
