const workSpaceUpdateMixin = {


  methods: {

    
    validateWorkSpaceNameRule() {
      /* the first parameter is the value , the second is name of the field , the third is list of funcion names as array and fourth is validation and message object key */
      this.validateField(
        this.workspaceName,
        "Workspace Name",
        [
          "validateRequired",
          "validateMinLength",
          "validateMaxLength",
        ],
        [
          "workspaceName","workspaceName"
        ],
      );
     
      // if any of the validation is true then return false so that backend request can be prevented
      return this.validations.workspaceName 
        ? false
        : true;
    },

    UrlValidationCheck(){
      return this.Validations.url  || this.Validations.urlValid  || this.Validations.urlLength ;
    }

  },

  computed: {
    eitherUpdateBasicSetting(){
      //TODO : fix URLinputValidations
      return (
        this.validations.workspaceName ||
        this.UrlValidationCheck() ||
        this.getBrandLoaders.image_upload ||
        (this.workspaceName.trim().length === 0 &&
          (() => {
            if (this.getBrandAdd.url === null || this.getBrandAdd.url.trim() === "") {
              return true;
            } else {
              return false;
            }
          })()
      ));  
    }
  },
}

export {workSpaceUpdateMixin}
